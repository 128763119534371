import React from 'react';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';
import "../styles/components/mainHeader.css";

// icons
import MoonIcon from '../images/icons/moon.png';
import FistIcon from '../images/icons/fist.png';


const Mainheader = ({ classes, bgColor1, bgColor2, bgColor3, bgColor4, bg1Stop, bg2Stop, bg3Stop, bg4Stop, currentPage, noise, variants }) => {

    const bgStyles = {
        "--bg-1": bgColor1 ? bgColor1 : "#00070D",
        "--bg-2": bgColor2 ? bgColor2 : "#00070D",
        "--bg-3": bgColor3 ? bgColor3 : bgColor2,
        "--bg-4": bgColor4 ? bgColor4 : bgColor2,
        "--bg1-stop": bg1Stop ? bg1Stop : "50%",
        "--bg2-stop": bg2Stop ? bg2Stop : "50%",
        "--bg3-stop": bg3Stop ? bg3Stop : "100%",
        "--bg4-stop": bg4Stop ? bg4Stop : "100%",
    }

    return (
        <motion.header
            className={`main-header ${classes} ${noise ? 'noise-bg' : ''}`}
            role="navigation"
            variants={variants ? variants : ''}
            initial="initial"
            animate="animate"
            exit="exit"
            transition="transition"
        >
            <div className="master-bg-block" style={bgStyles}></div>

            <div className="header-wrapper">
                <nav>
                    <ul>
                        <li>
                            <Link to="/musings"
                                activeClassName='active-link'
                                className={currentPage === 'musings' ? 'active-link' : ''}
                                aria-current={currentPage === 'musings' ? 'page' : ''}
                            >Musings</Link>
                        </li>
                        <li>
                            <Link to="/about"
                                activeClassName='active-link'
                                className={currentPage === 'about' ? 'active-link' : ''}
                                aria-current={currentPage === 'about' ? 'page' : ''}
                            >About</Link>
                        </li>
                        <li>
                            <Link to="/work"
                                activeClassName='active-link'
                                className={currentPage === 'work' ? 'active-link' : ''}
                                aria-current={currentPage === 'work' ? 'page' : ''}
                            >Work</Link>
                        </li>
                    </ul>
                </nav>

                <Link className="header-title" to="/">
                    <img src={MoonIcon}
                        alt="🌙"
                        draggable="false"
                        loading='lazy'
                    />
                    <span>
                        Portfolio of Nihar Bhagat
                    </span>
                </Link>

                <Link to="/contact" className='header-contact-link' activeClassName='active-link' >
                    <span>
                        Contact
                    </span>
                    <img src={FistIcon}
                        alt="✊"
                        draggable="false"
                        loading='lazy'
                    />
                </Link>
            </div>

            <div className="header-ruler"></div>
        </motion.header>
    );
}

export default Mainheader;
