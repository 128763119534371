import React, { useState } from 'react';
import Styleswrapper from '../../components/StylesWrapper';
import PageMeta from '../../components/blocks/helmet/PageMeta';
import Mainheader from '../../components/MainHeader';
import { motion } from 'framer-motion';

import './index.css';

import MusingsArtwork from '../../images/musings/background.jpg';

const Musings = () => {
    const [bgLoaded, setBgLoaded] = useState(false);

    const slowEaseOut = [.44, -0.02, .1, 1];

    const bgVariants = {
        initial: {
            opacity: 0,
            scale: 1.2,
        },
        animate: bgLoaded ? {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 6,
                ease: slowEaseOut,
            }
        } : {
            opacity: 0,
            scale: 1.2,
        },
    }

    return (
        <Styleswrapper
            bgColor1="#00070D"
            bgColor2="#00070D"
            bg1Stop="0%"
            bg2Stop="20%"
            noise="true"
        >

            <PageMeta
                theme="#00070D"
                title="Musings — Nihar Bhagat"
                description=""
                image="../images/social/contact/default.jpg"
                twitterImage="../images/social/contact/twitter.jpg"
            />

            <Mainheader
                bgColor1="#00070D"
                bgColor2="#00070D"
                bg1Stop="0%"
                bg2Stop="35%"
                noise="true"
                classes="musings-header"
            />

            <section className="musings-main">
                <div className="musings-artwork-wrapper">
                    <motion.img
                        src={MusingsArtwork}
                        alt=""
                        draggable="false"
                        loading="eager"
                        onLoad={() => setBgLoaded(true)}
                        variants={bgVariants}
                        initial="initial"
                        animate="animate"
                    />
                </div>

                <div className="musings-main-wrapper">
                    <div className="musings-copy">
                        <div className="line">
                            Musings. Musings. Musings. Musings. Musings. Musings.
                        </div>
                        <div className="line">
                            Musings. Musings. Musings. Musings. Musings.
                        </div>
                        <div className="line">
                            Musings. Musings. Musings. Musings.
                        </div>
                        <div className="line">
                            Musings. Musings. Musings.
                        </div>
                        <div className="line">
                            Musings. Musings.
                        </div>
                        <div className="line">
                            Musings.
                        </div>
                        <p className='uploading-soon'>
                            Uploading soon
                        </p>
                    </div>
                </div>
            </section>

        </Styleswrapper>
    );
}

export default Musings;
